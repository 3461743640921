export const DONATIONS_lIST = [
    {
        name: 'BTC',
        value: '1Bmfg9qbmw3r1qwbtuyupVMsC26iVSTp8h'
    },
    {
        name: 'ETH (ERC20)',
        value: '0x8dd980f2e7864478671cf2053688c23bbbf206ff'
    },
    {
        name: 'USDT Tron (TRC20)',
        value: 'TE5RwW1iiy8iFKvr6KuRP5Ts4SYiukCwpP'
    },
]